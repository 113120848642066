<ng-container *ngIf="id$ | async">
    <div *ngIf="isClosed === false" @Grow class="grow mb-5">
        <div class="border-b-2 border-eip-danger-400 box p-5 bg-eip-danger-100 rounded-2xl">
            <div class="flex content-center mb-4">
                <span class="grow text-base font-semibold text-gray-800" [innerHTML]="'blockades.limit.title' | translate"></span>
                <app-icon (click)="close()" class="cursor-pointer" name="mdi:close"></app-icon>
            </div>
            <div class="text-xs text-gray-800" [innerHTML]="'blockades.limit.description' | translate"></div>
        </div>
    </div>
</ng-container>
