import { Injectable, inject } from '@angular/core';
import { combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { PriceTotalPipe } from '@app/modules/cart/pipes/total-price.pipe';
import { LocalStorage } from '@library';
import { UserBlocked } from '@app/store/models';
import { ShoppingCartFacade } from '@app/store/shopping-cart';
import { UserFacade } from '@app/store/user';

@Injectable({
    providedIn: 'root',
})
export class BlockadesService {
    private readonly userFacade = inject(UserFacade);
    private readonly shoppingCartFacade = inject(ShoppingCartFacade);
    private readonly totalPipe = inject(PriceTotalPipe);
    readonly closeKey = 'close';
    private storageKey = '';

    private readonly cartValue$ = this.shoppingCartFacade.cart$.pipe(map((cart) => this.totalPipe.transform(cart)));
    private readonly limitFeatureEnabled$ = this.userFacade.features$.pipe(map((features) => features.includes('merchant-limit')));
    private readonly limitVerificationEnabled$ = combineLatest([this.userFacade.skipCreditLimitVerification$, this.limitFeatureEnabled$]).pipe(
        filter(([skip, limitFeatureEnabled]) => skip === false && limitFeatureEnabled === true),
        map(([skip, limitFeatureEnabled]) => skip === false && limitFeatureEnabled === true),
        distinctUntilChanged(),
    );

    private readonly cartValueCondition$ = combineLatest([this.userFacade.user$, this.cartValue$]).pipe(
        map(([user, cartValue]) => user.salespersonInfo !== null && cartValue !== null && cartValue > user.salespersonInfo.creditLimitLcy),
        distinctUntilChanged(),
    );

    private readonly shipmentBalanceCondition$ = this.userFacade.user$.pipe(
        map((user) => {
            const shipmentBalanceEnabled = user.features.includes('merchant-limit.condition.shipment-balance');

            return (
                shipmentBalanceEnabled &&
                user.salespersonInfo !== null &&
                user.salespersonInfo.balance + user.salespersonInfo.shipmentBalance >= user.salespersonInfo.creditLimitLcy
            );
        }),
        distinctUntilChanged(),
    );

    private readonly limitExceeded$ = combineLatest([this.cartValueCondition$, this.shipmentBalanceCondition$]).pipe(
        map(([cartValueCondition, shipmentBalanceCondition]) => cartValueCondition || shipmentBalanceCondition),
    );

    creditLimitExceeded$ = combineLatest([this.limitVerificationEnabled$, this.limitExceeded$]).pipe(
        map((conditions) => conditions.every((condition) => condition === true)),
    );

    get isClosed() {
        return LocalStorage.read(this.storageKey) === this.closeKey;
    }

    setStorageKey(type: UserBlocked, id: string) {
        this.storageKey = type + id;
    }

    close() {
        LocalStorage.write(this.storageKey, this.closeKey);
    }

    clear() {
        if (this.storageKey === '') return;
        LocalStorage.write(this.storageKey, '');
        this.storageKey = '';
    }
}
